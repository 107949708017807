<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAConsultations',
  metaInfo: { title: 'Consultations' },
  extends: View,

  mixins: [
    LoadSections([
      'KDAConsultForm',
      'KDAConsultDescription',
      'KDAConsultPoint',
      'KDAConsultReview',
      'KDAConsultCompanyLogo',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'consultations'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Consultations'
    })
  },
};
</script>
